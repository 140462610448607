import { initializeApp } from "firebase/app";
import { getToken, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging/sw";

const isDevelopment = true;
export const ROOT_URI =
  "https://tmgroup.auth.eu-west-2.amazoncognito.com";
export const COGNITO_URI =
  "https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_K80SUFzCl";
export const WELL_KNOW =
  "https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_K80SUFzCl/.well-known/openid-configuration";
// export const CLIENT_ID =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNseWdyaXoxaTAwMmxhMDkyaGo5bHVhbnQiLCJpYXQiOjE3MjA2NzE3MzksImV4cCI6MTcyMzI2MzczOX0.sw_GkJugW6JSo3LgWt8AyqZ7eY5ndZSpu2vu1dvUruQ";
// export const CLIENT_SECRET =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNseWdyaXoxaTAwMmxhMDkyaGo5bHVhbnQiLCJpYXQiOjE3MjA2NzE3MzksImV4cCI6MTcyMzI2MzczOX0.sw_GkJugW6JSo3LgWt8AyqZ7eY5ndZSpu2vu1dvUruQ";
// export const CLIENT_NAME =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNseWdyaXoxaTAwMmxhMDkyaGo5bHVhbnQiLCJpYXQiOjE3MjA2NzE3MzksImV4cCI6MTcyMzI2MzczOX0.sw_GkJugW6JSo3LgWt8AyqZ7eY5ndZSpu2vu1dvUruQ";4
export const CLIENT_ID = '1sp5663ef820vqrb7vem7uphn3';
export const CLIENT_SECRET = '1l1toqncupfqka3l9uhjugdj1eohsidne12iqln8itocf37nmhbg';
export const REDIRECT_URI = isDevelopment
  ? "http://localhost:3000/login/callback"
  : `${window.location.origin}/login/callback`;
export const LOGOUT_URI = isDevelopment
  ? "http://localhost:3000/logout"
  : `${window.location.origin}/logout`;
export const LOGOUT_REDIRECT_URI = `${ROOT_URI}/logout?client_id=${CLIENT_ID}&logout_uri=${LOGOUT_URI}`;

const firebaseConfig = {
  apiKey: "AIzaSyC2DrV8cY-R5MZG1GwtBqi9C-HV8KCa71Q",
  authDomain: "market-place-e8208.firebaseapp.com",
  projectId: "market-place-e8208",
  storageBucket: "market-place-e8208.appspot.com",
  messagingSenderId: "139934815468",
  appId: "1:139934815468:web:dad61481e513120780c1c9",
  measurementId: "G-MJVP17YZEH",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
const db = getFirestore(app);

export { messaging, getToken, onMessage, db };
